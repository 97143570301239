import { createBreakpoint, useMedia as _useMedia } from "react-use";

type Breakpoints = keyof typeof Breakpoints;
export const Breakpoints = {
  TabletMin: 768,
} as const;

export const MediaSizes = {
  TabletMin: `min-width: ${Breakpoints.TabletMin}px`,
} as const;

export const Media = (size: keyof typeof MediaSizes) =>
  `@media only screen and (${MediaSizes[size]})`;

export const useBreakpoint = () => {
  return createBreakpoint(Breakpoints)() as Breakpoints;
};

export const useMedia = (size: keyof typeof MediaSizes) => {
  return _useMedia(`(${MediaSizes[size]})`);
};
