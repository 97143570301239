import React, { useContext, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Menu, Image, Sidebar, Dropdown, DropdownItem } from "semantic-ui-react";
import "./assets/styles.css";
import { NavLink, useNavigate } from "react-router-dom";
import { rootRoutes, routes } from "./routes";
import { useMedia } from "./breakpoints";

export const Navigation = () => {
    const isTabletMin = useMedia("TabletMin");
    return (
        <div className="styles-top" style={{ paddingBottom: "1%" }}>
        { isTabletMin ? <DesktopNavigation /> : <MobileNavigation />}
      </div>
    );
};

const DesktopNavigation = () => {
    const navigate = useNavigate();
    return (
        <>
            
        </>
    );
};

const MobileNavigation = () => {
    return (
        <>
        </>
    );
};