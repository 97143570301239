import React from 'react';

import './App.css';
import { Route, Routes } from 'react-router-dom';
import { rootRoutes, routes } from "./routes";
import { Navigation } from "./navigation";
import { Home } from "./home";

function App() {
  return (
    <div>
      <Navigation/>
      <Routes>
        <Route path = {rootRoutes.home} element={<Home />}/>
      </Routes>
    </div>
  );
}

export default App;
