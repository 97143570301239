import React, {Component} from "react";
import { routes } from "./routes";
import {Button, Image, Card, Icon, Grid, GridColumn, GridRow, CardContent } from "semantic-ui-react";
import logo from "./assets/logo.jpg"
import mistyHeadshot from "./assets/MistyHeadShot.jpg";
import siarraHeadshot from "./assets/siarraHeadShot.jpg";
import usdLogo from "./assets/usdLogo.png";
import {Link} from 'react-router-dom'
export const Home = () => {
    return (
        <>
            {/* Video Header */}
            <section className = "styles-container styles-display-container"style={{height:"600px"}}>
                <video data-aid="HEADER_VIDEO" poster="https://img1.wsimg.com/isteam/videos/uA41GmyyG8IMaxXdb"
                    style={{ width: "100%", display: "block", position: "absolute", top: "50%", left: "50%", transform: "translateX(-50%) translateY(-50%)", height: "auto", minHeight: "100%", objectFit: "cover" }}
                    loop autoPlay playsInline muted src="https://websites.godaddy.com/categories/v4/videos/raw/video/uA41GmyyG8IMaxXdb">
                    
                </video>
                <div className = "styles-text-gold"style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%,-50%)", textAlign:"center", zIndex:"1"}}>
                    <h1 style={{ fontSize: "50px", WebkitTextStrokeWidth: "1px", WebkitTextStrokeColor:"#051336" }}>
                        TechVentures Studios
                    </h1>
                    <h4 style={{ fontSize: "18px", WebkitTextStrokeWidth: ".3px", WebkitTextStrokeColor: "#051336" }}>
                        Helping make your company's next adventure tech and user friendly
                    </h4>
                </div>
                <div className="styles-navy-background styles-text-gold"
                style={{ height: "100%", paddingTop:"2%", flex:"1"}}>
            
                <Image size='small' src={logo} style={{ display: "flex", flexDirection:"right", borderRadius:"50%"}} />
                <h1 style = {{marginLeft:"10px"}}> TechVentures Studios</h1>               
                    </div>
                    
            </section>
           
            {/* Services Offered */}
            <section className="styles-display-container styles-gold-background styles-text-navy styles-centered"
                style={{ paddingTop: "3%", paddingBottom:"3%" }} >
                <div className= "styles-center" >
                    <h2>
                        <Link to="" className = "styles-text-navy">Services Offered</Link>
                    </h2>
                </div>
                <div className = "styles-center">
                    <h5> Software Development</h5>
                    
                </div>
                <div className = "styles-center">
                    <h5> Application Development</h5>
                </div>
                <div className = "styles-center">
                    <h5> Website Development</h5>
                </div>
            </section>

            {/* Team */}
            <section className="styles-container styles-display-container styles-navy-background styles-text-gold"
                style={{ height: "100%", paddingTop: "5%" }}>
                <div className = "styles-center">
                    <h2>Meet the team</h2>
                </div>
                    <div className="styles-display-container styles-center" style={{paddingTop:"5%"}}>
                    <Grid columns={2} style={{ margin: 8 }}>
                        <GridRow>
                        <GridColumn width={8}>
                            <Card>
                                <Image src={siarraHeadshot} wrapped ui={false} />
                                <Card.Content>
                                    <Card.Header>Siarra Gremillion</Card.Header>
                                    <Card.Meta>Role: Co-Owner</Card.Meta>
                                    <Card.Description>
                                        Siarra is a LSU alumni who works for knowledgevine.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <a>
                                        <Icon name='book' />
                                        BS in Computer Science with a concentration in Software Engineering
                                    </a>
                                </Card.Content>
                                <Card.Content extra>
                                    <a>
                                        <Icon name='mail' />
                                        siarra.gremillion@gmail.com
                                    </a>
                                </Card.Content>
                            </Card>
                        </GridColumn>
                        <GridColumn width={8}>
                            <Card>
                                <Image src={mistyHeadshot} wrapped ui={false} />
                                <Card.Content>
                                    <Card.Header>Misty Burrow</Card.Header>
                                    <Card.Meta>Role: Co-Owner</Card.Meta>
                                    <Card.Description>
                                        Misty is pursuing a triple major at LSU and has studied abroad in Germany and Spain.
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <a>
                                        <Icon name='book' />
                                        BS in Computer Science with a concentration in CyberSecurity,
                                        a BS in Electrical Engineering, and a BS in Computer Engineering.
                                    </a>
                                </Card.Content>
                                <Card.Content extra>
                                    <a>
                                        <Icon name='mail' />
                                        mburrow125@gmail.com
                                        </a>
                                </Card.Content>
                                <Card.Content extra>
                                    <a>
                                        <Icon name='chain' />
                                        <Link to = "" > www.mistyburrow.com </Link>
                                    </a>
                                    </Card.Content>
                            </Card>
                            </GridColumn>
                        </GridRow>
                        <GridRow>
                            <GridColumn>
                        <Card>
                                <Image wrapped ui={false} />
                                <Card.Content>
                                    <Card.Header>Madelin Bourget</Card.Header>
                                    <Card.Meta>Role: Designer</Card.Meta>
                                    <Card.Description>
                                       Madelin is a high school junior who will be attending college in 2024 to pursue a degree in Computer Science
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <a>
                                        <Icon name='book' />
                                        High school junior
                                    </a>
                                </Card.Content>
                                </Card>
                            </GridColumn>
                            <Card>
                                <Image wrapped ui={false} />
                                <Card.Content>
                                    <Card.Header>Rashad Newsome</Card.Header>
                                    <Card.Meta>Role: Marketing</Card.Meta>
                                    <Card.Description>
                                      
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <a>
                                        <Icon name='book' />
                                    </a>
                                </Card.Content>
                                </Card>
                        </GridRow>
                    </Grid>
                </div>
            </section>
            <section  className="styles-display-container styles-gold-background styles-text-navy styles-centered"
                style={{ paddingTop: "3%", paddingBottom: "3%" }}>
                <div className="styles-center">
                   <h2>Current/Past Projects </h2> 
                </div>
                <div className="styles-display-container, styles-center" style={{ paddingTop: "5%" }}>
                    <Grid columns={2} style={{ margin: 8 }}>
                        <GridColumn width={16}>
                            <Card className = "styles-navy-background">
                                <Card.Content >
                                    <Image src={usdLogo} wrapped style={{paddingBottom:"10%"}} />
                                    <Card.Header className = "styles-text-white">Utility Service Database</Card.Header>
                                    <Card.Meta className = "styles-text-white">(USD)</Card.Meta>
                                    <Card.Description className = "styles-text-white">recovers bad debt for utility companies</Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                <Icon name='chain' className = "styles-text-white"/>
                                    <Link to ="" className = "styles-text-white">www.utilityservicedatabase.com</Link>
                                </Card.Content>
                            </Card>
                        </GridColumn>
                    </Grid>
                </div>
            </section>
            <section>
                <footer className = "styles-center" style={{backgroundColor:"black", fontSize:"10px", color:"white"}}>
                Copyright © 2023 TechVentures Studios - All Rights Reserved.
                </footer>
            </section>
            
        </>
    );
};